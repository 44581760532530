import React from 'react'

const Project = ({imagen, titulo, subtitulo, width, url}) => {


  return (
    <div className="flex flex-col md:flex-row items-center gap-5 md:gap-10 border-b-[1px] border-b-gray-500 pb-5 md:pb-0 md:border-b-0">
      <div className="md:w-2/5 flex justify-center">
        {url ? 
          (
            <a href={url} target="_blank" rel="noreferrer">
              <img className={`rounded-3xl ${width ? width : 'w-[600px]'}`} src={imagen} alt={titulo}/>
            </a>
          ) 
        : (
            <img className={`rounded-3xl ${width ? width : 'w-[600px]'}`} src={imagen} alt={titulo}/>
          )
        }

      </div>
      <div className="flex flex-col gap-4 md:w-3/5">
        <p className="text-2xl font-semibold">{titulo}</p>
        <p className="text-gray-300">{subtitulo}</p>
      </div>
    </div>
  )
}

export default Project
