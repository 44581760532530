import React from 'react'
import { Link } from 'react-router-dom'

const Tester = () => {
  return (
    <div className=' bg-[#090909] text-white w-full h-screen p-5 '>
        <div className="py-[7%] px-[10%] border-white h-full border rounded">
            <div className='text-2xl md:text-3xl font-semibold mb-10'>
                ¿Quieres ser tester de las aplicaciones?
            </div>
            <div className='text-lg md:text-xl mb-20 text-gray-200'>
                Desarrollo aplicaciones web y aplicaciones para <span class="text-white font-semibold">android</span>
            </div>
            <div className='text-lg md:text-xl'>
                Contacto conmigo en mi <Link to='https://discord.gg/fRt4ZsMkfm' class="text-blue-300">discord</Link>
            </div>
        </div>
    </div>
  )
}

export default Tester
