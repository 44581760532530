import { Link } from 'react-router-dom'
import profilePic from '../images/hadi.png'
import linux from '../images/svg/linux.svg'
import css from '../images/svg/css.svg'
import docker from '../images/svg/docker.svg'
import hacker from '../images/svg/hacker.svg'
import javascript from '../images/svg/javascript.svg'
import html from '../images/svg/html.svg'
import sql from '../images/svg/sql.svg'
import react from '../images/svg/react.svg'
import mail from '../images/svg/mail.svg'
import github from '../images/svg/github.svg'
import mongodb from '../images/svg/mongodb.svg'
import nodejs from '../images/svg/node.svg'
import php from '../images/svg/php.svg'
import laravel from '../images/svg/laravel.svg'

const About = () => {
    return (
        <div className=' bg-[#090909] text-white w-full h-full p-5 '>
            <div className="py-[7%] px-[10%] border-white border rounded">
                <div className="flex justify-center items-center w-full">
                    <div className="md:flex flex-row items-center gap-10 mr-[10%]">
                        <div className='md:text-right ml-5 md:ml-0'>
                            <h1 className="text-3xl md:text-6xl">Hadi</h1>
                            <h3 className="text-xl md:text-3xl text-gray-400">Estudiante en 42Malaga</h3>
                            <h3 className="text-xl md:text-3xl text-gray-400">Desarrollador de aplicaciones</h3>
                        </div>
                        <div className='flex justify-center mt-10 md:mt-0'>
                            <img src={profilePic}
                                className='w-28 md:w-40'
                                alt='hadi paint'    
                            />
                        </div>
                    </div>
                    
                </div>
                <div className="flex flex-wrap gap-5 justify-center mt-10">
                    <div className="flex flex-row  gap-2 bg-[#46a758] px-3 py-1 rounded-xl">
                        <div>
                            <img className="w-6" src={mail} alt='mail' />
                        </div>
                        <div>
                            contacto@hadi.es
                        </div>
                    </div>
                    <Link to='https://github.com/Hadi2022'>
                    <div className="flex flex-row  gap-2 bg-[#46a758] px-3 py-1 rounded-xl">
                        <div>
                            <img className="w-6" src={github} alt='mail' />
                        </div>
                        <div>
                            GitHub
                        </div>
                    </div>
                    </Link>
                </div>
                <div className='mt-20 text-xl text-gray-100 flex flex-col gap-5 border-b-[1px] border-b-gray-800 pb-10'>
                    <div className='text-2xl font-bold'>
                        Sobre mí
                    </div>
                    <div className="flex flex-col gap-2 text-base md:text-lg">
                        <p>¡Hola! Soy un estudiante de informatica y autodidacta en desarrollo de aplicaciones web y móviles.</p>
                        <p>
                            Actualmente estoy estudiando en 
                            {' '}
                            <Link to="https://42malaga.com" className="text-blue-500">
                                42Malaga
                            </Link>
                            {', '}
                            el campus más innovador del mundo donde aprendemos a programar lenguajes como C o C++
                        </p>
                    </div>
                </div>

                <div className='mt-20 text-xl text-gray-100 flex flex-col gap-5 border-b-[1px] border-b-gray-800 pb-10'>
                    <div className='text-2xl font-bold'>
                        Experiencia
                    </div>
                    <div className="flex flex-col gap-2 text-base md:text-lg">
                        <p>· Enero 2024 - Febrero 2024 {'->'} FP Dual en el Ayuntamiento de Torremolinos</p>
                        
                    </div>
                </div>

                <div className='mt-12 text-gray-100 flex flex-col gap-5 '>
                    <div className='text-xl md:text-2xl font-bold'>
                        Conocimientos
                    </div>
                    <div className="flex flex-wrap gap-2 text-base md:text-lg">
                        <div className="w-36 flex items-center justify-center gap-3 p-2 rounded-3xl bg-[#70fe8c1b]">
                            <img className="w-6" src={html} alt="javascript" />
                            <p className='text-[#89ff9fcd]'>HTML</p>
                        </div>
                        <div className="w-36 flex items-center justify-center gap-3 p-2 rounded-3xl bg-[#70fe8c1b]">
                            <img className="w-6" src={css} alt="javascript" />
                            <p className='text-[#89ff9fcd]'>CSS</p>
                        </div>
                        <div className="w-36 flex items-center justify-center gap-3 p-2 rounded-3xl bg-[#70fe8c1b]">
                            <img className="w-6" src={php} alt="javascript" />
                            <p className='text-[#89ff9fcd]'>PHP</p>
                        </div>
                        <div className="w-36 flex items-center justify-center gap-3 p-2 rounded-3xl bg-[#70fe8c1b]">
                            <img className="w-6" src={sql} alt="javascript" />
                            <p className='text-[#89ff9fcd]'>SQL</p>
                        </div>
                        <div className="w-64 flex items-center justify-center gap-3 p-2 rounded-3xl bg-[#70fe8c1b]">
                            <img className="w-6" src={react} alt="javascript" />
                            <p className='text-[#89ff9fcd]'>React + React Native</p>
                        </div>
                        <div className="w-36 flex items-center justify-center gap-3 p-2 rounded-3xl bg-[#70fe8c1b]">
                            <img className="w-6" src={laravel} alt="javascript" />
                            <p className='text-[#89ff9fcd]'>Laravel</p>
                        </div>
                        <div className="w-36 flex items-center justify-center gap-3 p-2 rounded-3xl bg-[#70fe8c1b]">
                            <img className="w-6" src={javascript} alt="javascript" />
                            <p className='text-[#89ff9fcd]'>Javascript</p>
                        </div>
                        <div className="w-36 flex items-center justify-center gap-3 p-2 rounded-3xl bg-[#70fe8c1b]">
                            <img className="w-6" src={linux} alt="javascript" />
                            <p className='text-[#89ff9fcd]'>Linux</p>
                        </div>
                    </div>
                </div>

                <div className='mt-12 text-gray-100 flex flex-col gap-5 border-b-[1px] border-b-gray-800 pb-10'>
                    <div className='text-xl md:text-2xl font-bold'>
                        Aprendiendo
                    </div>
                    <div className="flex flex-wrap gap-2 text-base md:text-lg">
                        <div className="w-40 flex items-center justify-center gap-3 p-2 rounded-3xl bg-[#70fe8c1b]">
                            <img className="w-6" src={nodejs} alt="javascript" />
                            <p className='text-[#89ff9fcd]'>NodeJS</p>
                        </div>
                        <div className="w-40 flex items-center justify-center gap-3 p-2 rounded-3xl bg-[#70fe8c1b]">
                            <img className="w-6" src={mongodb} alt="javascript" />
                            <p className='text-[#89ff9fcd]'>MongoDB</p>
                        </div>
                        <div className="w-36 flex items-center justify-center gap-3 p-2 rounded-3xl bg-[#70fe8c1b]">
                            <img className="w-6" src={docker} alt="javascript" />
                            <p className='text-[#89ff9fcd]'>Docker</p>
                        </div>
                        <div className="w-48 flex items-center justify-center gap-3 p-2 rounded-3xl bg-[#70fe8c1b]">
                            <img className="w-6" src={hacker} alt="javascript" />
                            <p className='text-[#89ff9fcd]'>Ciberseguridad</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
      )
}

export default About
