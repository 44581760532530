import { Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import About from "./components/About"
import Projects from "./components/Projects";
import Tester from "./components/Tester";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="flex flex-row bg-[#090909]">
      <div className="fixed top-0 left-0 h-[101vh] w-1/5">
        <Sidebar />
      </div>
      <div className="ml-[33%] md:ml-[20%] w-4/5 h-auto">
        <Routes>
          <Route index path="/" element={<About />}/>
          <Route path="/proyectos" element={<Projects />}/>
          <Route path="/ser-tester" element={<Tester />}/>
          <Route path="/contacto" element={<Contact />}/>
        </Routes>
      </div>
    </div>
  );
}

export default App;
