import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const Sidebar = () => {
    
    const location = useLocation()

    const pathname = location.pathname

  return (
    <div className='flex flex-col bg-[#121524] min-w-36 text-white w-full h-screen p-5'>
        <div className='flex items-center justify-center uppercase font-bold text-base lg:text-3xl xl:text-4xl h-full max-h-[9%] min-h-[7%]'>
            hadi - es
        </div>
        <div className='flex flex-col items-center h-full w-full text-base lg:text-xl xl:text-2xl'>
            <div className='flex flex-col gap-5 text-right w-full pt-[40%] pr-[10%]'>

                <div className='font-bold uppercase '>
                    <Link to="/"
                        className={`hover:text-blue-400 transition-colors ${pathname === '/' ? 'text-blue-400' : ''}`}
                    >
                        Inicio
                    </Link>
                </div>

                <div className=' font-bold uppercase '>
                    <Link to="/proyectos"
                        className={`hover:text-blue-400 transition-colors ${pathname === '/proyectos' ? 'text-blue-400' : ''}`}
                        >
                        Proyectos
                    </Link>
                </div>

                <div className=' font-bold uppercase '>
                    <Link to="/ser-tester"
                        className={`hover:text-blue-400 transition-colors ${pathname === '/ser-tester' ? 'text-blue-400' : ''}`}
                        >
                        Ser Tester
                    </Link>
                </div>

                <div className=' font-bold uppercase '>
                    <Link to="/contacto"
                        className={`hover:text-blue-400 transition-colors ${pathname === '/contacto' ? 'text-blue-400' : ''}`}
                        >
                        Contacto
                    </Link>
                </div>

            </div>
        </div>
    </div>
  )
}

export default Sidebar
