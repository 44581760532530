import React from 'react'
import { Link } from 'react-router-dom'
import mail from '../images/svg/mail.svg'
import discord from '../images/svg/discord.svg'
import whatsapp from '../images/svg/whatsapp.svg'


const Contact = () => {
  return (
    <div className=' bg-[#090909] text-white w-full h-screen p-5 '>
      <div className="py-[7%] px-[10%] border-white h-full border rounded">
        <div className='text-xl md:text-3xl font-semibold mb-10'>
          <h1>Contacta conmigo</h1>
        </div>

        <div class="ml-1 md:ml-14">
          <div className='flex flex-col md:flex-row md:items-center gap-5 text-base md:text-xl mb-5 text-gray-200'>
            Mi correo electronico:
            <div class="flex">
              <div className="flex flex-row items-center gap-2  text-xs md:text-base bg-[#a74646] px-2 md:px-4 py-1 rounded-xl">
                <div>
                  <img className="w-6" src={mail} alt='mail' />
                </div>
                <div>
                  contacto@hadi.es
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col md:flex-row md:items-center gap-5 text-base md:text-xl mb-20 text-gray-200'>
            Mi discord:
            <div class="flex">
              <Link to='https://discord.gg/fRt4ZsMkfm'>
                <div className="flex flex-row items-center gap-2 text-xs md:text-base bg-[#467ba7] px-3 py-1 rounded-xl hover:bg-[#3b688d] transition-colors">
                  <div>
                    <img className="w-6" src={discord} alt='discord' />
                  </div>
                  <div>
                    Únete
                  </div>
                </div>
              </Link>
            </div>
          </div>



        </div>

        <div>
          <div className='text-lg md:text-3xl font-semibold mb-10'>
            <h1>O estate al tanto de las novedades</h1>
          </div>
          <div className='flex flex-col md:flex-row md:items-center gap-5 text-lg md:text-xl mb-20 text-gray-200 ml-1 md:ml-14'>
            Comunidad:
            <div class="flex">
              <Link to='https://whatsapp.com/channel/0029ValX4N1AjPXHXImrUo16'>
                <div className="flex flex-row items-center gap-2 text-xs md:text-base bg-[#46a74e] px-3 py-1 rounded-xl hover:bg-[#3b8d42] transition-colors">
                  <div>
                    <img className="w-6" src={whatsapp} alt='whatsapp' />
                  </div>
                  <div>
                    Únete
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
